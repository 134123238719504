<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('article.articles') }}</h2>
    </div>
    <el-card class="box-card">

      <div class="filter-container">
        <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterArticles" />
        <el-select v-model="query.status" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('general.status')" style="width: 100%;" @change="handleFilterArticles">
          <el-option v-for="(_status, index) in articlestatus" :key="index" :label="_status | uppercaseFirst" :value="_status" />
        </el-select>
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterArticles">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-if="checkPermission(['create report'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateArticle">
          {{ $t('general.add') }}
        </el-button>
      </div>

      <el-table v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="" width="80">
          <template slot-scope="scope">
            <div class="demo-basic--circle">
              <div>
                <el-avatar shape="square" :size="50" :src="scope.row.image_uri" />
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column min-width="250px" label="Title">
          <template slot-scope="{row}">
            <router-link :to="'/articles/edit/'+row.id" class="link-type">
              <span>{{ row.title }}</span>
            </router-link>
          </template>
        </el-table-column>

        <el-table-column width="100" label="Importance">
          <template slot-scope="scope">
            <i v-for="n in +scope.row.importance" :key="n" class="meta-item__icon el-icon-star-on" />
          </template>
        </el-table-column>

        <el-table-column class-name="status-col" label="Status" width="180">
          <template slot-scope="{row}">
            <el-tag v-show="row.status!='Approval required'" :type="row.status | statusFilter">
              {{ row.status | uppercaseFirst }}
            </el-tag>
            <el-button v-show="row.status=='Approval required'" round plain type="info" size="small" @click="approuve(row)"><i class="el-icon-view" /> Approval required</el-button>
          </template>
        </el-table-column>

        <el-table-column width="150px" align="center" label="Date">
          <template slot-scope="scope">
            <span>{{ scope.row.timestamp | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
          </template>
        </el-table-column>

        <el-table-column width="200px" align="center" label="Author">
          <template slot-scope="scope">
            <span>{{ scope.row.author.name }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Actions" width="210">
          <template slot-scope="{row}">
            <el-button type="primary" size="small" icon="el-icon-view" @click="viewDetail(row)" />
            <router-link :to="'/articles/edit/'+row.id">
              <el-button type="primary" size="small" icon="el-icon-edit" />
            </router-link>
            <el-button type="danger" size="small" icon="el-icon-delete" @click="handleDeletePost(row.id, row.is_visible, row.name)" />
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getList" />
    </el-card>

    <!-- ======= LOCALITE CREATE DRAWER ======= -->
    <el-drawer
      ref="ArticleCreationDrawer"
      :title="$t('article.Addarticle')"
      :visible.sync="dialogCreateArticle"
      :before-close="handleCloseArticleCreateDraw"
      :loading="articleCreating"
      size="40%"
      class="article-drawer"
    >
      <div class="drawer-body">
        <div class="localaites-steps">
          <el-steps align-center :space="300" :active="steps.current" finish-status="success">
            <el-step title="Title" />
            <el-step title="Summary" />
            <el-step title="Category" />
            <el-step title="Tags" />
          </el-steps>
        </div>
        <div class="user-create-form content-inputs">
          <el-form ref="currentArticle" :model="currentArticle" :rules="rules" class="form-container">
            <!-- ======= TITLE ======= -->
            <div v-show="steps.current==0" class="region-step">
              <div class="select-region">
                <vs-alert shadow class="mb-15">
                  <template #title>
                    {{ $t('article.addTitle') }}
                  </template>
                  {{ $t('article.addTitleandImageMessage') }}
                </vs-alert>
                <el-form-item style="margin-bottom: 40px;" prop="title">
                  <MDinput v-model="currentArticle.title" :maxlength="100" name="name" required>
                    Title
                  </MDinput>
                </el-form-item>
                <el-form-item prop="image_uri" style="margin-bottom: 30px;">
                  <Upload
                    v-model="currentArticle.image_uri"
                    :uploadurl="thumbPostUrl"
                  />
                </el-form-item>
                <!--el-select v-model="currentZone.region_id" :disabled="createNewRegion" class="filter-item" :placeholder="$t('region.region')" style="width: 80%;" prop="region_id" @change="gotoNext()">
                  <el-option
                    v-for="(region, idex) in regions"
                    :key="idex"
                    :label="region.nom | uppercaseFirst"
                    :value="region.id"
                    :disabled="!region.is_active"
                  />
                </el-select>
                <el-button type="primary" :icon="createNewRegion ? 'el-icon-minus' : 'el-icon-plus'" circle @click="createNewRegion=!createNewRegion" /-->
              </div>
              <!--div v-show="createNewRegion" class="region-step-form step-form">
                <el-form ref="regionForm" :rules="regionRules" :model="currentRegion" label-position="left">
                  <el-form-item :label="$t('region.nom')" prop="nom">
                    <el-input v-model="currentRegion.nom" />
                  </el-form-item>
                  <el-form-item :label="$t('general.description')" prop="description">
                    <el-input v-model="currentRegion.description" type="textarea" />
                  </el-form-item>
                  <div style="text-align:right;padding-top:12px">
                    <el-button plain type="danger" @click="createNewRegion=!createNewRegion">
                      {{ $t('permission.cancel') }}
                    </el-button>
                    <el-button type="primary" :loading="articleCreating" @click="createRegion()">
                      {{ articleCreating ? $t('general.saving') : $t('general.save') }}
                    </el-button>
                  </div>
                </el-form>
              </div-->
            </div>
            <!-- ======= TITLE ======= -->

            <!-- ======= Summary ======= -->
            <div v-show="steps.current==1" class="zone-step">
              <div class="select-zone">
                <vs-alert shadow class="mb-15">
                  <template #title>
                    {{ $t('article.addSummary') }}
                  </template>
                  {{ $t('article.addSummaryMessage') }}
                </vs-alert>
                <el-form-item style="margin-bottom: 40px;" label-width="80px" label="Summary:">
                  <el-input
                    v-model="currentArticle.content_short"
                    :rows="1"
                    type="textarea"
                    class="article-textarea"
                    autosize
                    placeholder="Please enter the content"
                  />
                  <span v-show="contentShortLength" class="word-counter">{{ contentShortLength }} word</span>
                </el-form-item>
                <el-form-item
                  label-width="80px"
                  label="Important:"
                  class="postInfo-container-item"
                >
                  <el-rate
                    v-model="currentArticle.importance"
                    :max="3"
                    :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
                    :low-threshold="1"
                    :high-threshold="3"
                    style="margin-top:8px;"
                  />
                </el-form-item>
              </div>
            </div>
            <!-- ======= FIN Summary ======= -->

            <!-- ======= Category ======= -->
            <div v-show="steps.current==2" class="categorie-step">
              <div class="select-categorie">
                <vs-alert shadow class="mb-15">
                  <template #title>
                    {{ $t('article.addCategory') }}
                  </template>
                  {{ $t('article.addCategoryMessage') }}
                </vs-alert>
                <el-form-item label-width="80px" label="Comment:" class="postInfo-container-item">
                  <CommentDropdown v-model="currentArticle.comment_disabled" />
                </el-form-item>
                <el-form-item label-width="80px" label="Category:" class="postInfo-container-item">
                  <el-select v-model="currentArticle.categorie_id" class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('categorie.label')" style="width: 100%;">
                    <el-option
                      v-for="(categorie, index) in categories"
                      :key="index"
                      :label="categorie.label | uppercaseFirst"
                      :value="categorie.id"
                      :disabled="!categorie.is_active"
                    />
                  </el-select>
                </el-form-item>
                <el-button type="primary" :icon="createNewCategorie ? 'el-icon-minus' : 'el-icon-plus'" circle @click="createNewCategorie=!createNewCategorie" />
              </div>
              <div v-show="createNewCategorie" class="categorie-step-form step-form">
                <el-form ref="categorieForm" :rules="categorieRules" :model="currentCategorie" label-position="left">
                  <el-form-item :label="$t('categorie.label')" prop="label">
                    <el-input v-model="currentCategorie.label" />
                  </el-form-item>
                  <el-form-item :label="$t('categorie.description')" prop="description">
                    <el-input v-model="currentCategorie.description" type="textarea" />
                  </el-form-item>
                  <div style="text-align:right;padding-top:12px">
                    <el-button plain type="danger" @click="createNewCategorie=!createNewCategorie">
                      {{ $t('permission.cancel') }}
                    </el-button>
                    <el-button type="primary" :loading="categorieCreating" @click="createCategorie()">
                      {{ categorieCreating ? $t('general.saving') : $t('general.save') }}
                    </el-button>
                  </div>
                </el-form>
              </div>
            </div>
            <!-- ======= FIN Category ======= -->

            <!-- ======= TAGS ======= -->
            <div v-show="steps.current==3" class="tag-step">
              <div class="select-tag">
                <vs-alert shadow class="mb-15">
                  <template #title>
                    {{ $t('article.addTag') }}
                  </template>
                  {{ $t('article.addTagMessage') }}
                </vs-alert>
                <el-form-item label-width="80px" label="Tags:" class="postInfo-container-item">
                  <el-select v-model="currentArticle.tags" multiple placeholder="Select">
                    <el-option
                      v-for="tag in tags"
                      :key="tag.id"
                      :label="tag.label | uppercaseFirst"
                      :value="tag.id"
                      :disabled="!tag.is_active"
                    />
                  </el-select>
                </el-form-item>
                <el-button type="primary" :icon="createNewTag ? 'el-icon-minus' : 'el-icon-plus'" circle @click="createNewTag=!createNewTag" />
                <el-form-item
                  label-width="120px"
                  label="Published date:"
                  class="postInfo-container-item"
                >
                  <el-date-picker
                    v-model="currentArticle.display_time"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm:ss"
                    placeholder="Select date and time"
                  />
                </el-form-item>
              </div>
              <div v-show="createNewTag" class="tag-step-form step-form">
                <el-form ref="tagForm" :rules="tagRules" :model="currentTag" label-position="left">
                  <el-form-item :label="$t('tag.label')" prop="label">
                    <el-input v-model="currentTag.label" />
                  </el-form-item>
                  <el-form-item :label="$t('general.description')" prop="description">
                    <el-input v-model="currentTag.description" type="textarea" />
                  </el-form-item>
                  <div style="text-align:right;padding-top:12px">
                    <el-button plain type="danger" @click="createNewTag=!createNewTag">
                      {{ $t('permission.cancel') }}
                    </el-button>
                    <el-button type="primary" :loading="tagCreating" @click="createTag()">
                      {{ tagCreating ? $t('general.saving') : $t('general.save') }}
                    </el-button>
                  </div>
                </el-form>
              </div>
            </div>
            <!-- ======= FIN TAGS ======= -->

            <div class="controls">
              <hr>
              <el-button-group>
                <el-button type="secondary" icon="el-icon-arrow-left" @click="goBack()" />
                <el-button type="primary" :icon="steps.current == 3 ? 'el-icon-s-promotion' : 'el-icon-arrow-right'" @click="gotoNext()" />
              </el-button-group>
              <el-alert
                v-show="steps.hasError"
                :title="steps.errorTitle"
                type="error"
                :description="steps.errorMessage"
                show-icon
              />
            </div>
          </el-form>
        </div>
      </div>

    </el-drawer>

    <el-dialog
      v-if="currentPost!=null"
      :title="$t('article.postDetails')"
      :visible.sync="openDetails"
      width="50%"
    >
      <div class="article-details">
        <el-row :gutter="20">
          <el-col :span="16">
            <h2>{{ currentPost.title }}</h2>
          </el-col>
          <el-col :span="8">
            <h2>
              <el-tag v-show="currentPost.status!='Approval required'" :type="currentPost.status | statusFilter">
                {{ currentPost.status | uppercaseFirst }}
              </el-tag>
              <el-button v-show="currentPost.status=='Approval required'" round plain type="info" size="small" @click="approuve(currentPost)"><i class="el-icon-view" /> Approval required</el-button>
            </h2>
          </el-col>
        </el-row>
        <el-image
          style="width: 100%; height: 350px"
          :src="currentPost.image_uri"
          fit="cover"
        />
      </div>
      <div class="h-full-width">
        <div class="half-photo">
          <div class="demo-basic--circle">
            <div>
              <el-avatar shape="square" :size="50" :src="currentPost.author.avatar" />
            </div>
          </div>
        </div>
        <div class="user-details">
          <div class="in-td">
            <h4>{{ currentPost.author.name | uppercaseFirst }}</h4>
            <small>{{ currentPost.author.email }}</small>
          </div>
        </div>
      </div>
      <el-divider />
      <div class="post-content">
        <p v-html="currentPost.content" />
      </div>
    </el-dialog>
    <!-- ======= LOCALITE CREATE DRAWER ======= -->
  </div>
</template>

<script>
import defaultSettings from '@/settings';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import Resource from '@/api/resource';
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import waves from '@/directive/waves'; // Directives
import Upload from '@/components/Upload/SingleImage';
import MDinput from '@/components/MDinput';
import {
  CommentDropdown,
  // PlatformDropdown,
  // SourceUrlDropdown,
} from './components/Dropdown';
const categorieResource = new Resource('categories');
const tagResource = new Resource('tags');
const articleResource = new Resource('articles');

export default {
  name: 'ArticleList',
  directives: { waves },
  components: {
    Pagination,
    MDinput,
    Upload,
    CommentDropdown,
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger',
      };
      return statusMap[status];
    },
  },
  data() {
    const validateRequire = (rule, value, callback) => {
      if (value === '') {
        this.$message({
          message: rule.field + ' is required',
          type: 'error',
        });
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    return {
      thumbPostUrl: defaultSettings.appbaseUrl + '/upload.php', // this.$appbaseUrl + 'upload.php', // 'https://httpbin.org/post',
      postimageData: {},
      tags: [],
      categories: [],
      thirdpratyquery: {
        page: 1,
        limit: 0,
      },
      articleCreating: false,
      createNewCategorie: false,
      createNewTag: false,
      categorieCreating: false,
      tagCreating: false,
      rules: {
        image_uri: [{ validator: validateRequire }],
        title: [{ validator: validateRequire }],
        content: [{ validator: validateRequire }],
      },
      tagRules: {
        label: [{ required: true, message: this.$t('tag.LabelRequired'), trigger: 'blur' }],
      },
      categorieRules: {
        label: [{ required: true, message: this.$t('categorie.LabelRequired'), trigger: 'blur' }],
        description: [{ required: true, message: this.$t('categorie.DescriptionRequired'), trigger: 'blur' }],
      },
      steps: {
        current: 0,
        hasError: false,
        errorTitle: this.$t('general.error'),
        errorMessage: '',
      },
      articlestatus: this.$articles_status,
      editing: false,
      dialogCreateArticle: false,
      currentCategorie: {},
      currentTag: {},
      currentArticle: {
        categorie_id: '',
        comment_disabled: false,
        content: '',
        content_short: '',
        display_time: new Date(),
        image_uri: '',
        importance: 1,
        pageviews: 0,
        status: 'draft',
        title: '',
      },
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        type: '',
      },
      list: null,
      total: 0,
      total_cats: 0,
      total_tags: 0,
      listLoading: true,
      currentPost: null,
      openDetails: false,
    };
  },
  computed: {
    contentShortLength() {
      return this.currentArticle.content_short.length;
    },
  },
  created() {
    this.getList();
    this.getCategoriesList();
    this.getTagsList();
  },
  methods: {
    checkPermission,
    checkRole,
    goBack(){
      if (this.steps.current > 0){
        this.steps.current -= 1;
      }
    },
    viewDetail(row){
      if (row.islink){
        window.open(row.title, '_blank');
      } else {
        this.currentPost = row;
        this.openDetails = true;
      }
    },
    approuve(row){
      this.$swal({
        title: this.$t('article.approuvePost'),
        text: this.$t('article.approuvePostMessage'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('article.yesPublish'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentPost = row;
          this.currentPost.status = 'published';
          this.updateArticle();
        }
      });
    },
    updateArticle() {
      this.loading = true;
      articleResource.update(this.currentPost.id, this.currentPost).then(response => {
        this.$notify({
          title: 'Success',
          message: this.$t('article.isupdatedSuccessfully'),
          type: 'success',
          duration: 2000,
        });
        this.getList();
        this.loading = false;
      })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    gotoNext(){
      if (this.steps.current === 0){
        if (this.currentArticle.title){
          this.steps.hasError = false;
          this.steps.current = 1;
        } else {
          this.$message({
            message: this.$t('article.TitleRequired'),
            type: 'error',
            duration: 5 * 1000,
          });
          this.steps.errorMessage = this.$t('article.TitleRequired');
          this.steps.hasError = true;
        }
      } else if (this.steps.current === 1){
        if (this.currentArticle.content_short){
          this.steps.hasError = false;
          this.steps.current = 2;
        } else {
          this.$message({
            message: this.$t('article.SummaryRequired'),
            type: 'error',
            duration: 5 * 1000,
          });
          this.steps.errorMessage = this.$t('article.SummaryRequired');
          this.steps.hasError = true;
        }
      } else if (this.steps.current === 2){
        if (this.currentArticle.categorie_id){
          this.steps.hasError = false;
          this.steps.current = 3;
        } else {
          this.$message({
            message: this.$t('article.CategoryRequired'),
            type: 'error',
            duration: 5 * 1000,
          });
          this.steps.errorMessage = this.$t('article.CategoryRequired');
          this.steps.hasError = true;
        }
      } else if (this.steps.current === 3){
        this.createArticle();
      }
    },
    handleCloseArticleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelArticleForm() {
      this.steps.current === 0;
      this.$refs.ArticleCreationDrawer.closeDrawer();
    },
    async getCategoriesList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await categorieResource.list(this.query);
      this.categories = data;
      this.categories.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total_cats = meta.total;
      this.loading = false;
    },
    async getTagsList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await tagResource.list(this.query);
      this.tags = data;
      this.tags.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total_tags = meta.total;
      this.loading = false;
    },
    resetNewCategorie() {
      this.currentCategorie.label = '';
      this.currentCategorie.description = '';
    },
    createCategorie() {
      this.$refs['categorieForm'].validate((valid) => {
        if (valid) {
          this.currentCategorie.roles = [this.currentCategorie.role];
          this.categorieCreating = true;
          categorieResource
            .store(this.currentCategorie)
            .then(async(response) => {
              this.$message({
                message: this.$t('categorie.NewCategorie') + ' ' + this.currentCategorie.label + ' ' + this.$t('categorie.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentCategorieId = response.data.id;
              this.resetNewCategorie();
              this.createNewCategorie = false;
              this.getCategoriesList();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.categorieCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    handleFilterArticles() {
      this.thirdpratyquery.page = 1;
      this.getList();
    },
    handleFilterTags() {
      this.thirdpratyquery.page = 1;
      this.getTagsList();
    },

    handleDeletePost(id) {
      this.$confirm(this.$t('article.deleteWarn') + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        this.$swal({
          title: this.$t('general.SureQuestion'),
          text: this.$t('general.irrversibleMessage'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('general.ouiSupprimer'),
        }).then((result) => {
          if (result.isConfirmed) {
            articleResource.destroy(id).then(response => {
              this.$message({
                type: 'success',
                message: this.$t('user.suppressionEffectue'),
              });
              this.getList();
            }).catch(error => {
              console.log(error);
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    resetNewTag() {
      this.currentTag = {
        label: '',
        description: '',
      };
    },
    createTag() {
      this.$refs['tagForm'].validate((valid) => {
        if (valid) {
          this.currentTag.roles = [this.currentTag.role];
          this.tagCreating = true;
          tagResource
            .store(this.currentTag)
            .then(async(response) => {
              this.$message({
                message: this.$t('tag.NewTag') + ' ' + this.currentTag.label + ' ' + this.$t('tag.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentTagId = response.data.id;
              this.resetNewTag();
              this.createNewTag = false;
              this.handleFilterTags();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.tagCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    createArticle() {
      this.articleCreating = true;
      this.currentArticle.content = this.currentArticle.content_short;
      articleResource
        .store(this.currentArticle)
        .then(async(response) => {
          this.$message({
            message: this.$t('article.NewArticle') + ' ' + this.currentArticle.title + ' ' + this.$t('article.iscreatedSuccessfully'),
            type: 'success',
            duration: 5 * 1000,
          });
          const article_id = response.data.id;
          this.dialogCreateArticle = false;
          window.location.href = '/articles/edit/' + article_id;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.articleCreating = false;
          this.dialogCreateArticle = false;
        });
    },
    handleCreateArticle() {
      this.editing = false;
      this.resetNewArticle();
      this.dialogCreateArticle = true;
      // this.getBase64Image(this.$postThumUrl + this.currentArticle.image_uri, this.converted, 'jpg');
      /* this.$nextTick(() => {
        this.$refs['ArticleForm'].clearValidate();
      });*/
    },
    resetNewArticle() {
      this.currentArticle = {
        categorie_id: '',
        comment_disabled: false,
        content: '',
        content_short: '',
        display_time: new Date(),
        image_uri: '',
        importance: 1,
        pageviews: 0,
        status: 'draft',
        title: '',
      };
    },
    async getList() {
      this.listLoading = true;
      const { limit, page } = this.query;
      const { data, meta } = await articleResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.listLoading = false;
    },
  },
};
</script>

<style>
.user-details {
    padding: 10px;
}
.h-full-width {
    min-height: 40px;
    margin: 20px 0px;
}

.image-uploader {
    width: 100% !important;
}
.upload-container .image-preview{
    margin-left: 0px !important;
}
.upload-container .image-app-preview {
    margin-left: 0px!important;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.upload-container .image-app-preview {
    width: 100% !important;
}
</style>
